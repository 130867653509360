<template>
  <div>
    <a href="https://github.com/RommanirsMer" target="_blank"><img src="../assets/icon/github-icon.png"></a>
    <a href="https://www.linkedin.com/in/rommanirs-mer/"  target="_blank"><img src="../assets/icon/linkdIn-icon.png"></a>
    <a href="https://drive.google.com/file/d/1GgRqw1hjckIHySaGG7hkNEgXLF8YmHVO/view?usp=sharing" target="_blank" class="cv">CV</a>
  </div>
</template>

<script>
export default {
name: "SocialLink",
  methods:{
    openCV(){
      let pdf = 'https://drive.google.com/file/d/1GgRqw1hjckIHySaGG7hkNEgXLF8YmHVO/view?usp=sharing';
      window.open(pdf);
    }
  }
}
</script>

<style scoped>
  img{
    height: 30px;
    margin-right: 8px;
  }
  a{
    color: white;
    text-decoration: none;
  }


  .cv{
    margin-right: 3%;
    right: 0;
    text-decoration: none;
    bottom: 0;
    margin-bottom: 2%;

  }
  .cv{
    color: black;
    background: white;
    margin: 0 10px 0 10px;
    text-align: center;
  }
  .cv{
    padding: 0 16px 0 16px;
  }
  .cv{
    padding: 0 16px 0 16px;
    font-family: Aero-Matics-Display, sans-serif;
    font-size: 127%;
  }
</style>
