<template>
  <div id="app">
    <b-nav  align="right">
      <div class="stuff">
        <b-nav-item  href="#about"><p>ROMMANIRS<span> MER</span></p></b-nav-item>
      </div>
      <b-nav-item  href="#home" >HOME</b-nav-item>
      <b-nav-item href="#about">ABOUT</b-nav-item>
      <b-nav-item  href="#skill">SKILL</b-nav-item>
      <b-nav-item  href="#project">PROJECT</b-nav-item>
    </b-nav>
    <b-container>
      <Home/>
      <About/>
      <Skill/>
      <Project/>
    </b-container>
    <Footer/>
  </div>

</template>
<script>

import Footer from "@/components/Footer";
import Home from "@/components/Home";
import About from "@/components/About";
import Skill from "@/components/Skill";
import Project from "@/components/Project";
export default {
  name: 'App',
  components: {
    About,
    Home,
    Footer,
    Skill,
    Project
  },
}
</script>
<style>

@font-face {
  font-family: "Aero-Matics-Display";
  src: url("./assets/font/Aero-Matics-Display/Aero-Matics-Display-Regular.ttf") format("truetype")
}

  body{
    background: linear-gradient( rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)),url('assets/fond.jpg') no-repeat center fixed ;
    background-size: cover;
  }

  .container{
    max-width: 1700px;
  }

  .nav{
    margin-right: 3%;
    margin-top: 2%;
    right: 0;
    text-decoration: none;
    position: fixed;
    z-index: 500;
  }

  .nav-item a{
    color: black;
    background: white;
    margin: 0 10px 0 10px;
    text-align: center;
  }

  .nav-link{
    padding: 0 16px 0 16px;
    font-family: Aero-Matics-Display,sans-serif;
    font-size: 127%;
  }

  .stuff img {
    height: 100px;
  }

  .stuff p {
    font-family: Roboto-regular,sans-serif;
    display: inline;
  }

  .stuff span{
    font-family: Roboto-Bold, sans-serif;
  }

</style>
