<template>
  <div id="project">
  <b-row>
    <b-card-group deck>
      <b-card  class="title mb-2" bg-variant="dark" text-variant="white">
        <b-card-text>
          <h3>Current project</h3>
        </b-card-text>
      </b-card>
      <b-col>
      <b-card class="mb-2" img-src="../assets/aegis-arma.png" img-alt="Card image"  bg-variant="dark" text-variant="white" img-left>
        <b-card-text>
          <h3>AEGIS Armament: F.A.W.S</h3>
          <p>
            Currently in his Early research, it is a Solo/Multiplayer mech sandbox simulator game based on Armored core series from FromSoftware
            and Japaneses show like Gundam, Muv-Luv ...
            8 years have passed since the last armored core, there was a spiritual successor Daemon X Machina, but in my
            opinion this game lacks of identity. Since FromSoftware is mostly focus on souls like IP for a long time and
            deeply interested into game development, I have begun to learn Unreal Engine and c++ and decide to make my own
            take of an armored core style game. The key feature of the game is the pilot environment (cockpit Interaction, HOTAS)
            and the realism oh his physics (basic integration of real world physic rule).
            Here the concept: During the preludes of the 22 centuries, the world conflicts increase in power, in order to
            maintain their sovereignty and political power. The various world powers which have continued their arms race,
            Increasingly involve private military contractors. The different entities are jointly or individually developing.
            the new mechanized bipedal F.A.W.S (Fighter Attacker Weapon System) platform in response to the new geopolitical
            context, this platform complements the existing endowments.
          </p>
        </b-card-text>
      </b-card>
      </b-col>
    </b-card-group>
    <b-col>
    </b-col>
  </b-row>
    <b-card class="title" bg-variant="dark" text-variant="white">
      <b-card-text>
        <h3>Last Project</h3>
      </b-card-text>
    </b-card>
  <b-row>
    <b-col>
    <b-card-group deck>
      <b-card  class="mb-2" bg-variant="dark" text-variant="white">
        <b-card-text>
          <h3>Dashboard</h3>
          <p>
            Dashboard based on a JAVA backend made with SPRING gradle war and a react frontend.<br/>
            The goal was to make an app able to call third party API as a serve and return the data
            to the user as a widget. The dashboard is responsive and fully customizable, the user can add and remove
            widget, the backend server will remember the location of the widget and what kind of widget the user use.
            As a login solution the Google OAuth2 service is available to be able to log without creating an account.
            (since the database is down, I can't show more screenshot.).
            The project is readable <a href="https://github.com/RommanirsMer/dashboard" target="_blank">here</a>
          </p>
          <img src="../assets/dash.jpg" alt="dashboard widget">
        </b-card-text>
      </b-card>
      <b-card class="mb-2" bg-variant="dark" text-variant="white">
        <b-card-text>
          <h3>JobBoard</h3>
          <p>
            JobBoard based on the MongoDB Express VueJS NodeJs stack with a express REST API and VueJs frontend.
            The goal was to make an app which the user can apply to a job and add an offer. <br/>
            Due to some poor project management, only the CRUD operation and job display is operational.
            The project is readable <a href="https://github.com/RommanirsMer/jobboard" target="_blank">here</a>
          </p>
          <img src="../assets/JobBoard.jpg" alt="JobBoard">
        </b-card-text>
      </b-card>
    </b-card-group>
    </b-col>
    <b-col>
      <b-card-group deck>
        <b-card  class="mb-2" bg-variant="dark" text-variant="white">
          <b-card-text>
            <h3>OwlEyewear</h3>
            <p>
              Online project presentation made under Symfony 4 with a MySQL Database operated with Doctrine,
              the objective was to replicate the kickstarter page project and be able to send newsletters to the project backers or newsletter subscriber, the website is not online anymore.
              The project is readable <a href="https://github.com/RommanirsMer/OWL-Eyewear" target="_blank">here</a>.
            </p>

            <video controls width="250">
              <source src="../assets/owleyewear.mp4"
                      type="video/mp4">
              Sorry, your browser doesn't support embedded videos.
            </video>
          </b-card-text>
        </b-card>

      </b-card-group>
    </b-col>
  </b-row>
  </div>
</template>

<script>
export default {
  name: "Project"
}
</script>

<style scoped>
img{
  width: 70%;
}
.card-deck{
  margin-top: 2%;
  margin-bottom: 4%;
}
.card {
  width: auto;
  height: auto;
}
.card-body{
  width: auto;
}
.nav{
  margin-bottom: 100px;
}
</style>
