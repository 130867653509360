<template>
    <b-nav align="right" class="footer">
      <component v-bind:is="components"></component>
      <b-nav-item v-on:click=" onclick('socialLink')">MY LINK</b-nav-item>
    </b-nav>

</template>

<script>
import SocialLink from "@/components/SocialLink";

export default {
  name: "Footer",
  components:{
    socialLink: SocialLink
  },
  data(){
    return {
      state: 'hidden',
      components: this.components
    }
  },
  methods: {
    onclick(comp){
      if (this.components){
       this.components = ''
      }else this.components = comp
    }
  }
}


</script>

<style scoped>

@font-face {
  font-family: "Aero-Matics-Display";
  src: url("../assets/font/Aero-Matics-Display/Aero-Matics-Display-Regular.ttf") format("truetype")
}

.nav{
  margin-right: 3%;
  right: 0;
  text-decoration: none;
  bottom: 0;
  margin-bottom: 2%;
}
.nav-link{
  color: black;
  background: white;
  margin: 0 10px 0 10px;
  text-align: center;
}
.footer{
  padding: 0 16px 0 16px;
}
.nav-link{
  padding: 0 16px 0 16px;
  font-family: Aero-Matics-Display, sans-serif;
  font-size: 127%;
}
</style>
