<template>
  <div class="skill" id="skill">
    <b-row>
      <div class="head">
        <h3>MY CURRENT SKILL SET</h3>
      </div>
      <b-col>
        <b-card header="DEVELOPMENT" header-tag="header" title="Development skill" class="text-center" bg-variant="dark" text-variant="white">
          <b-card-text >
            <img src="../assets/icon/html-5.svg" alt="html5">
            <img src="../assets/icon/css-5.svg" alt="css3">
            <img src="../assets/icon/c--4.svg" alt="c#">
            <img src="../assets/icon/java-4.svg" alt="java">
            <img src="../assets/icon/javascript.svg" alt="javascript">
            <img src="../assets/icon/php-1.svg" alt="php">
            <h3>Database</h3>
            <img src="../assets/icon/mongodb.svg" alt="mongoDB">
            <img src="../assets/icon/mysql.svg" alt="mySql">
            <h3>Framework</h3>
            <img src="../assets/icon/vue-js-1.svg" alt="vueJs">
            <img src="../assets/icon/symfony.svg" alt="symfony">
            <img src="../assets/icon/bootstrap-4.svg" alt="bootstrap">
            <h3>Development tool</h3>
            <img src="../assets/icon/nodejs-icon.svg" alt="nodeJs">
            <img src="../assets/icon/visual-studio-2013.svg" alt="vsStudio">
            <img src="../assets/icon/visual-studio-code-1.svg" alt="csCode">
            <img src="../assets/icon/intellij-idea-1.svg" alt="intelIj">
            <h3>Version Control</h3>
            <img src="../assets/icon/github-icon.svg" alt="github">
            <img src="../assets/icon/git-icon.svg" alt="git">
          </b-card-text>
        </b-card>
      </b-col>
      <b-col>
        <b-card header="DESIGN" header-tag="header" title="Design skill"  class="text-center" bg-variant="dark" text-variant="white">
          <b-card-text>
            <p>Prototyping, make basic asset</p>
            <h3>Design Tool</h3>
              <img src="../assets/icon/photoshop-cc.svg" alt="photoshop">
              <img src="../assets/icon/adobe-xd.svg" alt="adobeXD">
          </b-card-text>
        </b-card>
      </b-col>
      <b-col>
        <b-card header="GAME DEVELOPMENT" header-tag="header" title="Game Development skill"  class="text-center" bg-variant="dark" text-variant="white">
          <b-card-text>
              <img src="../assets/icon/c.svg">
            <h3>development tool</h3>
              <img src="../assets/icon/unreal-1.svg" alt="unreal">
              <h3>3D modeling tool</h3>
              <img src="../assets/icon/substance-painter.svg" alt="substance">
              <img src="../assets/icon/blender.png" alt="blender">
              <h3>Version Control</h3>
              <img src="../assets/icon/perforce-black.png" alt="perforce">
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Skill"
}
</script>

<style scoped>
@font-face {
  font-family: "Roboto-regular";
  src: url("../assets/font/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../assets/font/Roboto/Roboto-Bold.ttf") format("truetype");
}
.skill{
  margin-top: 200px;
  margin-bottom: 200px;
}
.row{
  justify-content: center;
}
.card{
  margin-top: 50px;
}
.card img {
  height: 50px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 3px;
}
.card .card-header{
  font-family: Roboto-Bold, sans-serif;

}
.card .card-title{
  font-family: Roboto-regular, sans-serif;
}
.head h3{
  text-align: center;
  color: white;
  font-family: Roboto-Bold,sans-serif;
}

</style>
