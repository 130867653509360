<template>
  <div class="about" id="about">
  <b-row>
    <b-col>
      <b-img src="../assets/code.jpg"></b-img>
    </b-col>
    <b-col cols="8">
      <div class="aboutMe">
        <p><span class="bold">ABOUT ME</span></p>
        <p><span class="head">Greetings, I'm Rommanirs, thanks for looking my portfolio.</span> <br/>
            I'm Actually a 3rd year dev Student at EPITECH Strasbourg in France and I'm preparing a licence of web and mobile project manager.
            I'm looking for an apprenticeship in part time who can begins between January and March of 2021 for a period of 7 months as a developer of IT solution.
            Professionally, I work to become a game developer, for that during my free time I'm learning game development with Unreal Engine in C++
            and 3D tool like Blender.
        </p>
      </div>
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <b-img src="../assets/dcs.jpg"></b-img>
    </b-col>
    <b-col>
      <b-img src="../assets/Unreal.jpg"></b-img>
    </b-col>
    <b-col>
      <b-img src="../assets/dcs2.jpg"></b-img>
    </b-col>
  </b-row>
  </div>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped>
@font-face {
  font-family: "Roboto-regular";
  src: url("../assets/font/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../assets/font/Roboto/Roboto-Bold.ttf") format("truetype");
}
.about{
  right: 0;
  left: 0;
}
p{
  color: white;
  font-family: 'Roboto-regular', sans-serif;
  font-size: 16px;

}
.aboutMe{
  margin-top: 175px;
}
.bold{
  font-family: 'Roboto-Bold', sans-serif;
  font-size: 30px;
}
.head{
  font-family: 'Roboto-Bold', sans-serif;
  font-size: 20px;
}
img{
  height: 300px;
  margin-top: 125px;
  filter: brightness(100%);
}
</style>
