<template>
  <div class="home" id="home">
    <b-row>
      <b-col cols="4">
        <b-img src="../assets/ags-armament-logo.png"></b-img>
      </b-col>
      <b-col cols="8">
        <p>
          I'M <span class="bold">ROMMANIRS MER</span><br/>
          AND I'M AN IT <span class="medium">DEVELOPER STUDENT</span> </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Home"
}
</script>

<style scoped>
@font-face {
  font-family: "Roboto-regular";
  src: url("../assets/font/Roboto/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("../assets/font/Roboto/Roboto-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("../assets/font/Roboto/Roboto-Bold.ttf") format("truetype");
}

.home{
  margin-bottom: 200px;
}

img{
  height: 545px;
  right: 1px;
  margin-top: 230px;
}
p{
  color: white;
  font-family: 'Roboto-regular', sans-serif;
  font-size: 60px;
  margin-left: 80px;
  margin-top: 400px;
}
 .medium
{
  font-family: 'Roboto-Medium', sans-serif;
}
.bold{
  font-family: 'Roboto-Bold', sans-serif;
}
</style>
